const About = () => {
  return (
    <div className="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-4 flex-1">
      <div className="flex flex-col lg:flex-row justify-between gap-8">
        <div className="w-full lg:w-5/12 flex flex-col justify-center">
          <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-gray-800 pb-4">About Us</h1>
          <p className="font-normal text-base leading-6 text-gray-600 ">
            As a search engine company targeting social casino gamers, we understand the importance of delivering
            accurate and relevant results for social casino-related queries. Our team has optimized our search engine to
            ensure that users can easily find the information they are looking for, whether they are searching for tips
            and strategies for their favorite social casino games, or looking for new platforms to try out.
          </p>
          <br />
          <p>
            Our search engine uses advanced algorithms to analyze and rank social casino-related content based on
            factors such as relevance, popularity, and quality. We also take into account the specific needs and
            interests of social casino gamers, tailoring our search results to provide the most useful and engaging
            content possible. Whether you are a seasoned social casino player or just getting started, our search engine
            is designed to help you find the information you need to enhance your gaming experience. We are committed to
            providing the best possible search results for social casino-related queries, so you can spend less time
            searching and more time playing your favorite games.
          </p>
        </div>
        <div className="w-full lg:w-8/12 ">
          <img className="w-full h-full" src="https://i.ibb.co/FhgPJt8/Rectangle-116.png" alt="A group of People" />
        </div>
      </div>
    </div>
  );
};

export default About;
