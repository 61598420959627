import Routes from './routes';

// headers: {
//   "X-RapidAPI-Key": "87c97987e7msh6621cd9c1009e1ep1203adjsn639cbb4c8fe5",
//   "X-RapidAPI-Host": "contextualwebsearch-websearch-v1.p.rapidapi.com",
// },
// headers: {
//     'X-RapidAPI-Key': '9c2f08d2b1msh45a660bbaedb81dp179910jsnd3a3e3cfbcf8',
//     'X-RapidAPI-Host': 'contextualwebsearch-websearch-v1.p.rapidapi.com',

const App = () => {
  return <Routes />;
};

export default App;
