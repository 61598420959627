import { useEffect, useState } from 'react';
import useDebounce from './useDebounce';
import { useLocation, useNavigate } from 'react-router-dom';
import axios, { AxiosRequestConfig } from 'axios';
interface ApiParams {
  method: string;
  url: string;
  pageNumber: string;
  pageSize: string;
  rapidApiKey: string;
  rapidApiHost: string;
}

export const useSearch = ({ method, url, pageNumber, pageSize, rapidApiKey, rapidApiHost }: ApiParams) => {
  const localSearchHistory = localStorage.getItem('searchHistory');
  // const localSearchTerm = localStorage.getItem('searchTerm');
  const localData = localStorage.getItem('data');
  const [searchText, setSearchText] = useState('');
  const [searchHistory, setSearchHistory] = useState<string[]>(
    localSearchHistory ? JSON.parse(localSearchHistory) : [],
  );
  const [data, setData] = useState<any>(localData ? JSON.parse(localData) : []);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const searchParams = new URLSearchParams(location.search);
  const keyword = searchParams.get('keyword');
  useEffect(() => {
    if (keyword) navigate('/search');
  }, []);

  const debouncedValue = useDebounce(keyword ? keyword : searchText);
  const handleSearch = (e: any, recentSearch?: string) => {
    e.preventDefault();

    if (searchHistory.length >= 8) {
      setSearchHistory((prevState) => [...prevState.slice(1), recentSearch ? recentSearch : debouncedValue]);
    } else {
      debouncedValue && setSearchHistory([...searchHistory, recentSearch ? recentSearch : debouncedValue]);
    }
    localStorage.setItem('searchHistory', JSON.stringify(searchHistory));
    // localStorage.setItem('searchTerm', JSON.stringify(recentSearch ? recentSearch : debouncedValue));

    const fetchData = async () => {
      try {
        setLoading(true);
        const options: AxiosRequestConfig = {
          method: method,
          url: url,
          params: {
            q: recentSearch ? recentSearch : debouncedValue,
            pageNumber: pageNumber,
            pageSize: pageSize,
            autoCorrect: 'true',
          },
          headers: {
            'content-type': 'application/octet-stream',
            'X-RapidAPI-Key': rapidApiKey,
            'X-RapidAPI-Host': rapidApiHost,
          },
        };
        const response = await axios(options);
        localStorage.setItem('data', JSON.stringify(response.data.value));
        setData(response.data.value);
        setLoading(false);
        if (pathname !== '/search') navigate('/search');
      } catch (error: any) {
        setError(error.message || 'An error occurred');
        setLoading(false);
      }
    };
    if (recentSearch) setSearchText(recentSearch);
    fetchData();
  };

  return { searchText, setSearchText, handleSearch, searchHistory, debouncedValue, data, loading, error };
};
