import { Outlet } from 'react-router-dom';
import Footer from 'src/components/Footer';
import Header from 'src/components/Header';
interface LayoutProps {
  setMode: (mode: boolean) => void;
  mode: boolean;
}
const Layout = ({ setMode, mode }: LayoutProps) => {
  return (
    <div className={`flex flex-col ${mode ? 'bg-black text-red' : 'h-screen'}`}>
      <Header mode={mode} setMode={setMode} />
      <Outlet />
      <Footer />
    </div>
  );
};

export default Layout;
