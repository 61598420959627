const Privacy = () => {
  return (
    <div className="flex-1 container mx-auto px-4 py-6">
      <h2 className="text-lg font-bold mb-4">Privacy Policy</h2>
      <p className="mb-4">
        This Privacy Policy (the `&quot;Policy`&quot;) explains how we collect, use, and disclose information about you
        when you use our search engine platform (the `&quot;Platform`&quot;). By using the Platform, you consent to the
        processing of your information in accordance with this Policy.
      </p>
      <h3 className="text-md font-bold mb-2">Information We Collect</h3>
      <p className="mb-4">
        We may collect information about you when you use the Platform, including but not limited to your IP address,
        browser type, device type, and search queries. We may also collect information from third-party sources, such as
        social media platforms.
      </p>
      <h3 className="text-md font-bold mb-2">How We Use Information</h3>
      <p className="mb-4">
        We may use the information we collect to provide and improve the Platform, to personalize your experience, to
        communicate with you, and to comply with legal obligations.
      </p>
      <h3 className="text-md font-bold mb-2">Information Sharing</h3>
      <p className="mb-4">
        We may share your information with third-party service providers that help us operate the Platform, with our
        affiliates, and with other users of the Platform. We may also share your information with law enforcement or
        other government agencies in response to a subpoena, court order, or other legal request.
      </p>
      <h3 className="text-md font-bold mb-2">Cookies and Similar Technologies</h3>
      <p className="mb-4">
        We may use cookies and similar technologies to collect information about your use of the Platform, to provide
        personalized content and advertising, and to analyze trends and usage patterns. You may be able to control the
        use of cookies through your browser settings.
      </p>
      <h3 className="text-md font-bold mb-2">Security</h3>
      <p className="mb-4">
        We take reasonable measures to protect your information from unauthorized access, disclosure, or use. However,
        no security system is completely secure, and we cannot guarantee the security of your information.
      </p>
      <h3 className="text-md font-bold mb-2">Changes to this Policy</h3>
      <p className="mb-4">
        We may update this Policy from time to time, and we will notify you of any material changes by posting the new
        Policy on the Platform or by sending you an email or other notification. Your continued use of the Platform
        after any such changes constitutes your acceptance of the revised Policy.
      </p>
      <h3 className="text-md font-bold mb-2">Contact Us</h3>
      <p className="mb-4">
        If you have any questions or concerns about this Policy, please contact us at [insert contact information].
      </p>
    </div>
  );
};

export default Privacy;
