import { NavLink } from 'react-router-dom';

const Footer = () => {
  const activeLink = 'text-blue-700 font-bold';
  return (
    <nav className="bg-indigo-700 dark:bg-gray-900 w-full z-20 top-0 left-0  dark:border-gray-600">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <span className="block text-sm text-white sm:text-center dark:text-white">
          © 2023{' '}
          <NavLink to="/" className="hover:underline">
            Search Enginee™
          </NavLink>
          . All Rights Reserved.
        </span>

        <NavLink to="/" className="flex items-center">
          <img src="/img/logo.svg" className="h-8 mr-3" alt="Search enginee Logo" />
          <div className=" text-2xl font-extrabold text-gray-900 dark:text-dark md:text-2xl lg:text-2xl ">
            <span className="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">
              Socialcasinosearch
            </span>
            .fun
          </div>
        </NavLink>

        <div className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1" id="navbar-sticky">
          <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-indigo-700 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-indigo-700 dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
            <li>
              <NavLink
                to="/"
                className={`block py-2 pl-3 pr-4 text-white bg-white rounded md:bg-transparent ${({ isActive }: any) =>
                  isActive && ' md:text-white'} md:p-0 md:dark:text-blue-500`}
                aria-current="page"
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/about"
                className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-white md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
              >
                About
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/terms"
                className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-white md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
              >
                Terms
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/privacy"
                className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-white md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
              >
                Privacy
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contact"
                className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-white md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
              >
                Contact
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Footer;
