import ResultCard from './ResultCard';

interface Idata {
  data: any;
}
const ResultList = ({ data }: Idata) => {
  return (
    <div className="max-w-2xl w-full xl:w-auto flex gap-5 flex-col">
      <ResultCard data={data} />
    </div>
  );
};
export default ResultList;
