interface Idata {
  data: any;
}
const ResultCard = ({ data }: Idata) => {
  return data.map((item: any) => {
    return (
      <a
        key={item.id}
        href={item.url}
        target="_blank"
        rel="noreferrer"
        className="block  p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
      >
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{item.title}</h5>
        <div className="flex items-center space-x-4">
          <img className="w-6 h-6 rounded-full" src="/img/logo.svg" alt="" />
          <div className="font-medium dark:text-white">
            <div className="text-sm text-gray-500 dark:text-gray-400">{item.url}</div>
          </div>
        </div>

        <p className="font-normal text-gray-700 dark:text-gray-400 truncate">{item.description}</p>
      </a>
    );
  });
};
export default ResultCard;
