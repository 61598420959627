import * as React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { Avatar, Grid, Typography } from '@mui/material';
import { useSearch } from 'src/hooks/useSearch';
import { useNavigate } from 'react-router-dom';

interface SearchProps {
  searchHistory: string[];
  handleSearch: (e: any, recentSearch: string) => void;
}

const RecentSearch = ({ searchHistory, handleSearch }: SearchProps) => {
  return (
    <div className="flex flex-col items-center justify-center max-w-sm m-auto">
      <Typography className="p-4">Recently Searched</Typography>
      <Grid container spacing={{ xs: 0 }} className="text-center">
        {searchHistory.map((item) => (
          <Grid item xs={3} key={Math.random()}>
            <Tooltip title={item} onClick={(e) => handleSearch(e, item)}>
              <IconButton>
                <Avatar sx={{ width: 56, height: 56 }}>{item[0]}</Avatar>
              </IconButton>
            </Tooltip>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
export default RecentSearch;
