import RecentSearch from 'src/components/RecentSearch';
import SearchInput from 'src/components/SearchInput';
interface MainProps {
  setSearchText: (e: string) => void;
  searchText: string;
  handleSearch: (e: any) => void;
  searchHistory: string[];
  mode: boolean;
}
const Main = ({ setSearchText, searchText, handleSearch, searchHistory }: MainProps) => {
  return (
    <div style={{ height: '85vh' }} className="flex-grow grid items-center">
      <div>
        <SearchInput setSearchText={setSearchText} handleSearch={handleSearch} searchText={searchText} />
        <RecentSearch handleSearch={handleSearch} searchHistory={searchHistory} />
      </div>
    </div>
  );
};

export default Main;
