import { Spinner } from 'flowbite-react';
import ErrorMessage from 'src/components/Error';
import ResultList from 'src/components/ResultList';
import SearchInput from 'src/components/SearchInput';
import SideBar from 'src/components/SideBar';

interface Idata {
  data: any;
  setSearchText: (e: string) => void;
  searchText: string;
  handleSearch: (e: any) => void;
  loading: boolean;
  error: any;
}
const Search = ({ data, setSearchText, handleSearch, searchText, loading, error }: Idata) => {
  {
    error && <ErrorMessage error={error} />;
  }
  return (
    <div className="flex-grow">
      <SearchInput searchText={searchText} setSearchText={setSearchText} handleSearch={handleSearch} />
      <div className=" container mx-auto flex  p-4 justify-between items-start">
        {loading ? <Spinner /> : <ResultList data={data} />}
        <SideBar />
      </div>
    </div>
  );
};

export default Search;
