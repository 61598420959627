const Terms = () => {
  return (
    <div className="flex flex-col h-screen">
      <div className="flex-1 container mx-auto px-4 py-6">
        <h2 className="text-lg font-bold mb-4">Terms and Conditions</h2>
        <p className="mb-4">
          This terms and conditions agreement (the &quot;Agreement&quot;) sets forth the terms and conditions that apply
          to your use of our search engine platform (the &quot;Platform&quot;). By accessing or using the Platform, you
          agree to be bound by the terms and conditions of this Agreement.
        </p>
        <p className="mb-4">
          The Platform is intended for use by individuals who are at least 18 years of age or older. You are solely
          responsible for complying with all applicable laws and regulations in your use of the Platform.
        </p>
        <p className="mb-4">
          All content and materials on the Platform, including but not limited to text, graphics, logos, images, and
          software, are the property of our company or our licensors and are protected by intellectual property laws.
          You may not reproduce, modify, distribute, or display any content or materials from the Platform without our
          prior written consent.
        </p>
        <p className="mb-4">
          The Platform is provided on an &quot;as is&quot; and &quot;as available&quot; basis. We make no
          representations or warranties of any kind, express or implied, regarding the operation or use of the Platform,
          including but not limited to the accuracy, completeness, reliability, or suitability of the content or
          materials on the Platform. We disclaim all warranties, express or implied, including but not limited to
          implied warranties of merchantability, fitness for a particular purpose, and non-infringement.
        </p>
        <p className="mb-4">
          In no event shall we be liable for any damages, including but not limited to direct, indirect, incidental,
          special, or consequential damages, arising out of or in connection with your use of the Platform or your
          inability to use the Platform, even if we have been advised of the possibility of such damages.
        </p>
        <p className="mb-4">
          You agree to indemnify, defend, and hold us harmless from and against any and all claims, damages,
          liabilities, costs, and expenses, including reasonable attorneys&apos; fees, arising out of or in connection
          with your use of the Platform or your breach of this Agreement.
        </p>
        <p className="mb-4">
          We reserve the right to modify this Agreement at any time, without prior notice to you. Your continued use of
          the Platform after any such modifications constitutes your acceptance of the revised terms and conditions.
        </p>
        <p className="mb-4">
          This Agreement shall be governed by and construed in accordance with the laws of [insert jurisdiction]. Any
          dispute arising out of or in connection with this Agreement shall be resolved exclusively in the state or
          federal courts located in [insert jurisdiction].
        </p>
        <p className="mb-4">
          This Agreement constitutes the entire agreement between you and us with respect to your use of the Platform
          and supersedes all prior or contemporaneous communications and proposals, whether oral or written, between you
          and us.
        </p>
      </div>
    </div>
  );
};

export default Terms;
