import { useState } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';
import { useSearch } from 'src/hooks/useSearch';
import Layout from 'src/layouts/Layout';
import About from 'src/pages/About';
import Contact from 'src/pages/Contact';
import Main from 'src/pages/Main';
import NotFound from 'src/pages/NotFound';
import Privacy from 'src/pages/Privacy';
import Search from 'src/pages/Search';
import Terms from 'src/pages/Terms';

const Routes = () => {
  const [mode, setMode] = useState(false);
  const { searchText, setSearchText, handleSearch, searchHistory, data, loading, error } = useSearch({
    method: 'get',
    url: 'https://contextualwebsearch-websearch-v1.p.rapidapi.com/api/Search/WebSearchAPI',
    pageNumber: '1',
    pageSize: '10',
    rapidApiKey: 'ec02907ad4mshccc96ea498df868p135deejsnc7d5a6add0b6',
    rapidApiHost: 'contextualwebsearch-websearch-v1.p.rapidapi.com',
  });
  return useRoutes([
    {
      element: <Layout setMode={setMode} mode={mode} />,
      children: [
        {
          path: ROUTES.home,
          element: (
            <Main
              setSearchText={setSearchText}
              searchText={searchText}
              handleSearch={handleSearch}
              searchHistory={searchHistory}
              mode={mode}
            />
          ),
        },
        {
          path: ROUTES.search,
          element: (
            <Search
              data={data}
              loading={loading}
              error={error}
              searchText={searchText}
              setSearchText={setSearchText}
              handleSearch={handleSearch}
            />
          ),
        },
        {
          path: ROUTES.about,
          element: <About />,
        },
        {
          path: ROUTES.contact,
          element: <Contact />,
        },
        {
          path: ROUTES.terms,
          element: <Terms />,
        },
        {
          path: ROUTES.privacy,
          element: <Privacy />,
        },
        {
          path: ROUTES.notFount,
          element: <NotFound />,
        },
        {
          path: '*',
          element: <Navigate to={ROUTES.notFount} replace />,
        },
      ],
    },
  ]);
};

export default Routes;
